import {Button} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import React from "react";

type Props = {
    href: string
}

export const EditThisPageButton = ({href}: Props) => (
    <Button endIcon={<Edit/>} href={href}>
        Edit this page
    </Button>
)