import { useEffect} from "react";
import ReactDOM from "react-dom";
import {CopyToClipboardButton} from "@components/copy-to-clipboard-button";

export const useCopyCodeToClipboard = (querySelector: string, containerClassName = 'copyToClipBoardContainer') => {
    useEffect(() => {
        const findContainer = (element: Element) => element.querySelector(`.${containerClassName}`)
        const elements = document.querySelectorAll(querySelector)
        elements.forEach(element => {
            let container = findContainer(element) ?? undefined
            if(container === undefined){
                container = document.createElement("DIV")
                container.className = containerClassName
                element.insertBefore(container, element.firstChild)
            }
            ReactDOM.render(
                <div style={{position: 'relative'}}>
                    <CopyToClipboardButton style={{position: 'absolute', top: 0, right: 0}} text={element.textContent?.trim() ?? undefined}/>
                </div>,
                container
            )
        })
        return () => {
            elements.forEach(element => {
                const container = findContainer(element)
                if (container) {
                    // element.removeChild(container)
                }
            })
        }
    })
}