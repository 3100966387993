import {ArticleContainer} from "@components/article/article-container";
import React from "react";
import {typographyMixin} from "@lib/styles/theme-utils";
import {colorBoxMixin, faintColor, faintColorContrast, lineBorder} from "@lib/styles/theme-utils-2";
import {AsciidocArticleData} from "@lib/asciidoc/asciidoc-article";
import Title from "@components/text/title";
import {mouseDevice, touchOnlyDevice} from "@lib/jss/breakpoints";
import {useSyntaxHighlighter} from "@lib/hooks/use-syntax-highlighter";
import {hljsMixin} from "@lib/styles/code-style";
import {useCopyCodeToClipboard} from "@lib/hooks/use-copy-to-clipboard-components";
import {
    BLOCK_SPACING,
    blockMixin,
    buttonMixin,
    copyToClipBoardContainerClass,
    getCodeColor,
    headerMixin,
    marginYMixin,
    PARAGRAPH_SPACING,
    responsiveWidthMixin,
    SECTION_SPACING
} from "@lib/article/article-style";
import {styled} from "@mui/material";
import {Box} from "@mui/material";

interface Params {
    article: AsciidocArticleData
}

const Root = styled(Box)(({theme})=>({
    minHeight: '100vh',
    marginLeft: 0,
    marginRight: 0,
    ...hljsMixin(theme),
    ...typographyMixin(theme, 'body1'),
    '& h1': {
        ...headerMixin(theme, 'h1'),
    },
    '& h2': {
        ...headerMixin(theme, 'h2'),
    },
    '& h3': {
        ...headerMixin(theme, 'h3'),
    },
    '& h4': {
        ...headerMixin(theme, 'h4'),
    },
    '& h5': {
        ...headerMixin(theme, 'h5'),
    },
    '& h6': {
        ...headerMixin(theme, 'h6'),
    },
    '& #preamble, & .sect1, & .sect2, & .sect3, & .sect4, & .sect5, & .sect6': {
        ...marginYMixin(theme, SECTION_SPACING),
    },
    '& .paragraph, & .title, & .hdlist, & .dlist': {
        marginBottom: theme.spacing(PARAGRAPH_SPACING),
        // textAlign: 'justify',
        ...responsiveWidthMixin(theme),
    },
    '& img': {
        maxWidth: '100%', // Needed for build, but not needed for when running in dev mode, for uknown reason.
    },
    '& .imageblock': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        '& .content': {
            display: 'flex',
            justifyContent: 'center',
            '& img': {
                backgroundColor: theme.palette.common.white,
                ...blockMixin(theme),
            },
        },
    },
    '& .title': {
        ...typographyMixin(theme, 'caption'),
        // marginTop: theme.spacing(PARAGRAPH_SPACING),
        marginBottom: theme.spacing(BLOCK_SPACING),
        // Justify the text, and if it doesn't span the entire row, center it.
        display: 'inline-flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    '& > *': {},
    '& ul': {
        listStyleType: 'none',
        paddingLeft: '2em',
        '& li:before': {
            content: '"-"',
            position: 'absolute',
            marginLeft: '-1rem',
            boxSizing: 'border-box',
            color: theme.palette.text.disabled,
        },
    },
    '& ol': {
        paddingLeft: 2 * theme.typography.fontSize,
        paddingRight: theme.typography.fontSize,
    },
    '& li': {
        ...marginYMixin(theme, PARAGRAPH_SPACING),
    },
    '& .admonitionblock': {
        ...blockMixin(theme),
        '& table': {
            borderSpacing: theme.spacing(2),
        },
        '& tr': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& td': {
            // padding: theme.spacing(2),
        },
        '& .icon .title': {
            ...typographyMixin(theme, 'button'),
            margin: 0,
            // color: faintColorContrast(theme,)
        },
        '& .icon': {},
        ...colorBoxMixin(theme, theme.palette.action.hover),
        '&.note': {
            ...colorBoxMixin(theme, theme.palette.info.main),
        },
        '&.tip': {
            ...colorBoxMixin(theme, theme.palette.success.main),
        },
        '&.important': {
            ...colorBoxMixin(theme, theme.palette.error.main),
        },
        '&.caution': {
            ...colorBoxMixin(theme, theme.palette.warning.main),
        },
        '&.warning': {
            ...colorBoxMixin(theme, theme.palette.warning.main),
        },
    },
    '& .highlight': {
        borderRadius: theme.shape.borderRadius,
        '& .content': {
            ...blockMixin(theme),
        },
    },
    '& .listingblock': {
        display: 'flex',
        flexDirection: 'column-reverse',
        '& .content': {
            padding: theme.spacing(PARAGRAPH_SPACING),
            overflowX: 'auto',
            ...colorBoxMixin(theme, getCodeColor(theme)),
            ...blockMixin(theme),
            [theme.breakpoints.down('md')]: {
                borderRadius: 0,
            },
            [`& .${copyToClipBoardContainerClass}`]: {
                opacity: 0,
                transition: theme.transitions.create(['opacity']),
                [touchOnlyDevice]: {
                    display: 'none',
                },
            },
            [`&:hover .${copyToClipBoardContainerClass}`]: {
                opacity: 1,
            },
        },
    },
    '& .dlist dl': {
        '& dt': {
            ...marginYMixin(theme, PARAGRAPH_SPACING),
        },
        '& dd': {
            marginLeft: theme.spacing(PARAGRAPH_SPACING),
        },
    },
    '& code:not(pre code)': {
        ...colorBoxMixin(theme, getCodeColor(theme)),
        paddingLeft: theme.typography.fontSize / 4,
        paddingRight: theme.typography.fontSize / 4,
        [theme.breakpoints.down('xl')]: {
            wordWrap: 'break-word',
        },
    },
    '& a[href]': {
        color: theme.palette.secondary.main,
        transition: theme.transitions.create(['border-color']),
        borderBottom: lineBorder(theme.palette.secondary.main),
        [mouseDevice]: {
            '&:not(:hover)': {
                borderColor: faintColor(theme, theme.palette.secondary.main),
            },
        },
    },
    '& .menu, & .submenu, .menuitem, & .button': {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.typography.button.color,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 4,
        border: lineBorder(theme.palette.divider),
        whiteSpace: 'nowrap',
    },
    '& a .button': {
        ...buttonMixin(theme),
    },
    '& i.caret:after': {
        content: '">"',
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightLight,
    },
    '& b.caret': {
        fontSize: '1.5em',
    },
    '& .colist table tr td': {
        '& b': {
            ...typographyMixin(theme, 'button'),
            width: 2 * theme.typography.fontSize,
            height: 2 * theme.typography.fontSize,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: faintColor(theme, theme.palette.primary.main),
            color: faintColorContrast(theme, theme.palette.primary.main),
            marginRight: theme.spacing(1),
        },
    },
    '& .hdlist': {
        ...marginYMixin(theme, BLOCK_SPACING),
        '& table': {
            borderSpacing: `0px ${theme.spacing(PARAGRAPH_SPACING)}`,
        },
        '& tbody': {
            // display: 'flex',
            // flexDirection: 'column',
        },
        '& tr': {
            ...marginYMixin(theme, PARAGRAPH_SPACING),
        },
        '& .hdlist1': {
            // display: 'flex',
            textAlign: 'left',
            verticalAlign: 'initial',
            paddingRight: theme.spacing(1),
            ...typographyMixin(theme, 'subtitle2'),
        },
        '& .hdlist2': {},
    },
    '& #footnotes': {
        '& hr': {
            marginBottom: theme.spacing(BLOCK_SPACING),
        },
        '& .footnote': {
            ...marginYMixin(theme, PARAGRAPH_SPACING),
        },
    },
    '& details': {
        transition: theme.transitions.create(['margin', 'background-color']),
        [theme.breakpoints.up('sm')]: {
            borderRadius: theme.shape.borderRadius,
        },
        '& summary.title': {
            display: 'block',
            margin: 0,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            ...typographyMixin(theme, 'body1'),
            color: theme.palette.text.secondary,
            cursor: 'pointer',
            [theme.breakpoints.up('sm')]: {
                borderRadius: theme.shape.borderRadius,
            },
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus, &:focus-visible': {
                backgroundColor: theme.palette.action.selected,
                outline: 'none',
            },
        },
        '& summary.title::before': {
            content: '"▴"',
            display: 'inline-block',
            ...typographyMixin(theme, 'button'),
            fontSize: '1.25em',
            transition: theme.transitions.create(['transform']),
            transform: 'rotate(90deg)',
            marginRight: theme.spacing(1),
        },
        '&[open] summary.title': {
            // backgroundColor: theme.palette.action.selected,
        },
        '&[open]': {
            ...colorBoxMixin(theme, getCodeColor(theme)),
            // backgroundColor: theme.palette.action.selected,
            ...marginYMixin(theme, PARAGRAPH_SPACING),
        },
        '&[open] summary.title::before': {
            transform: 'rotate(180deg)',
        },
        '& .content': {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        '&:not([open]):not(:hover):not(:focus)': {
            borderColor: 'transparent',
        },
        '&[open] summary ~ *': {
            // TODO seems to be a type error somewhere here, if we uncomment the code
        // // Animation is only possible when showing. Also, the height cannot be gradual.
        // // To work around this, we would have to not use <details>, but use a stateful React component
        // animationName: '$showDetail',
        //     animationDuration: theme.transitions.duration.standard,
        //     animationTimingFunction: theme.transitions.easing.easeInOut,
        },
    },
    // "@keyframes showDetail": {
    //     "0%": {
    //         opacity: 0,
    //         // transform: "scaleY(0%) translateY(-50%)",
    //     },
    //     "100%": {
    //         opacity: 1,
    //         // transform: "scaleY(100%) translateY(0%)",
    //     },
    // },
}))

export const AsciidocArticle = ({article}: Params) => {
    useCopyCodeToClipboard('.listingblock .content', copyToClipBoardContainerClass)
    useSyntaxHighlighter([article.editUrl])
    return (
        <ArticleContainer>
            <Title timeToRead={article.attributes["time-to-read"]}>{article.title}</Title>
            <Root dangerouslySetInnerHTML={{__html: article.rawHtml}}/>
        </ArticleContainer>
    )
}
