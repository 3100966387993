import {NavigationInternalLinkData, NavigationSiblings} from "@lib/nav/link";
import {Button, Container, Divider} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {SuggestChanges} from "@components/asciidoc/suggest-changes";
import {EditThisPageButton} from "@components/asciidoc/edit-this-page";
import React from "react";
import Link from 'next/link';
import {GetEmbeddedUrlButton} from "@components/asciidoc/copy-embeddded-url";
import {styled} from "@mui/material";

const Row = styled(Container)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}))

export const ArticleFooter = ({editUrl, siblings}: { editUrl: string, siblings: NavigationSiblings }) => (<>
    <Row maxWidth='md' >
        {siblings.previous ? <NextOrPreviousButton item={siblings.previous} type='previous'/> : <div/>}
        {siblings.next ? <NextOrPreviousButton item={siblings.next} type='next'/> : <div/>}
    </Row>
    <Divider/>
    <Row maxWidth='md'>
        <SuggestChanges href={editUrl}/>
        <GetEmbeddedUrlButton/>
        <EditThisPageButton href={editUrl}/>
    </Row>
</>)


const NextOrPreviousButton = ({item, type}: { item: NavigationInternalLinkData, type: 'next' | 'previous' }) => (
    <Link passHref href={item.fullSlug}>
        <Button
            component='a'
            disableRipple
            startIcon={type === 'previous' ? <ChevronLeft/> : undefined}
            endIcon={type === 'next' ? <ChevronRight/> : undefined}
            color='primary'>
            {item.label}
        </Button>
    </Link>
)