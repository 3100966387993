import Page from "@components/page";
import StandardLayout from "@components/layout/standard-layout";
import React, {useMemo, useState} from "react";
import {TableOfContents} from "@components/table-of-contents";
import SideNav from "@components/side-nav/tree-nav/side-nav";
import {AsciidocArticle} from "@components/asciidoc/asciidoc-article";
import { AsciidocPageProps } from "@lib/pages/asciidoc-page";
import {findSiblings} from "@lib/nav/link";
import {ArticleFooter} from "@components/asciidoc/article-footer";

export const AsciidocArticlePage = ({navFolder, spaceTitle, ...variableProps}: AsciidocPageProps) => {
    const [{article, currentSlug}, setState] = useState(variableProps)

    // This is needed for page switching:
    //  When the app is routed to a new page, the properties will change, and the state must be updated accordingly
    useMemo(() => {
        if (currentSlug !== variableProps.currentSlug) {
            // Props were updated
            setState(variableProps)
        }
        // componentWillReceiveProps
    }, [variableProps,currentSlug]);

    const leftNav = <SideNav
        navFolder={navFolder}
        currentSlug={currentSlug}
    />

    const rightNav = (
        <TableOfContents dependencies={[variableProps.currentSlug]}/>
    )

    const siblings = findSiblings(navFolder, currentSlug)
    const footer = <ArticleFooter editUrl={article.editUrl} siblings={siblings}/>

    return (
        <Page name={article.title}>
            <StandardLayout
                title={spaceTitle}
                leftDrawerContent={leftNav}
                rightDrawerContent={rightNav}
                footer={footer}
            >
                <AsciidocArticle article={article}/>
            </StandardLayout>
        </Page>
    )
}