import {Button} from "@mui/material";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons/faBullhorn";

type Props = {
    href: string
}

export const SuggestChanges = ({href}: Props) => (
    <Button startIcon={<FontAwesomeIcon icon={faBullhorn}/>} href={href} disabled>
        Suggest Changes
    </Button>
)