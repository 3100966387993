import { IconButton, Tooltip} from "@mui/material";
import {FileCopy} from "@mui/icons-material";
import React, {CSSProperties, useState} from "react";
import {copyToClipboard} from "@lib/utils/clipboard";

type Props = {
    text?: string
    style?: CSSProperties
}

// Typescript cannot properly infer props, use unknown
export const CopyToClipboardButton = ({text, ...props}: Props) => {

    const [copied, setCopied] = useState(false)

    const handleClick = () => {
        if (text) {
            copyToClipboard(text)
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 4000)
        }
    }

    const message = copied ? 'Copied!' : 'Copy to clipboard'

    return (
        <Tooltip title={message} disableFocusListener disableTouchListener>
            <IconButton {...props} disabled={text === undefined} size='small' onClick={handleClick}>
                <FileCopy fontSize='small'/>
            </IconButton>
        </Tooltip>
    )
}